import debounce from 'lodash/debounce';

const showMarkers = true && window.location.href.includes('metk.ddev.site');
const markerColors = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D'];

const watchers = [];

// These are the configurable values for each watcher
const defaultOps = {
    triggerEl: null,
    after: null,
    start: 'top top',
    end: 'bottom top',
    markerColor: null,
};

const runHandlers = (force = false) => {
    // force is true on first run after page load
    watchers.forEach((listener) => listener.check(force))
}

const indexAll = () => {
    watchers.forEach((listener) => listener.index());
}
const drawMarkers = () => {
    if (showMarkers) {
        watchers.forEach((listener, index) => listener.drawMarker(index));
    }
}

const init = () => {
    // give page time to load before initing
    document.addEventListener('DOMContentLoaded', () => {
        setTimeout(() => {
            indexAll();
            drawMarkers();
            runHandlers(true);

            window.addEventListener('resize', debounce(() => {
                indexAll();
                drawMarkers();
                runHandlers();
            }, 200));

            window.addEventListener('scroll', (e) => {
                requestAnimationFrame(() => {
                    runHandlers();
                });
            });
        }, 200);
    });
}

init();

class ScrollWatcher {
    // boundaries will be relative to this el, but it is optional. Exact pixel values can also be supplied as boundaries (through functions)
    triggerEl;

    // start/end boundary OR fn
    start;
    end;

    // px value, auto calculated based on boundaries
    scrollStart;
    scrollEnd;

    wasActive;
    lastProgress;

    shouldSelfIndex;

    progressListeners; // every scroll event between 0-1
    toggleListeners; // enter, leave, enterBack, leaveBack
    indexListeners; // after scrollStart/end has been calculated; on load & resize

    markerColor;

    uuid;

    constructor(data = {}) {
        const opts = { ...defaultOps, ...(data || {}) };

        this.uuid = (Math.random() * 100000000).toFixed(0);
        this.triggerEl = this.resolveEl(opts.triggerEl);
        this.start = opts.start;
        this.end = opts.end;
        this.markerColor = opts.markerColor;
        this.after = opts.after;
        this.wasActive = null;
        this.scrollStart = null;
        this.scrollEnd = null;
        this.progressListeners = [];
        this.toggleListeners = [];
        this.indexListeners = [];
        this.lastProgress = null;
        this.shouldSelfIndex = !(this.after instanceof ScrollWatcher);

        if (this.after instanceof ScrollWatcher) {
            this.after.addIndexListener(() => {
                this.index(true);
            });
        }

        // this allows adding event listeners during creation of the watcher; it is just syntactic sugar for .add*Listener()
        if (typeof data.onProgress === 'function') {
            this.addProgressListener(data.onProgress);
        }
        if (typeof data.onToggle === 'function') {
            this.addToggleListener(data.onToggle);
        }
        if (typeof data.onIndex === 'function') {
            this.addIndexListener(data.onIndex);
        }

        // add self to global watchers array
        watchers.push(this)

        return this;
    }

    index(force = false) {
        if (!this.shouldSelfIndex && !force) return;
        let rect = null;
        if (this.triggerEl && this.triggerEl.offsetParent) {
            rect = this.triggerEl.getBoundingClientRect();
        }

        const relativeStart = this.after ? this.after.scrollEnd : null;
        this.scrollStart = this.resolveBoundary('start', this.start, rect, relativeStart);

        const relativeEnd = this.after ? this.after.scrollEnd : this.scrollStart;
        this.scrollEnd = this.resolveBoundary('end', this.end, rect, relativeEnd);

        this.runListener('index');
    }

    // checks if watcher is active and calls event listeners; this is where the magic happens
    check(force) {
        if (!this.isValid()) return;

        const isActive = this.isActive();

        // wasActive; if it was active last run but no longer is, we should run the handler one last time to reach the final value (0 or 1)
        const runProgressListeners = isActive || this.wasActive;

        const progress = (force || runProgressListeners) ? this.calcScrollProgress() : null;

        // Progress listeners
        if (force || runProgressListeners) {
            this.runListener('progress', progress);
        }

        // toggle listeners
        if (!force) {
            if (isActive && !this.wasActive) {
                if (this.lastProgress < 1) {
                    this.runListener('toggle', 'enter')
                } else if (this.lastProgress === 1) {
                    this.runListener('toggle', 'enterBack')
                }
            } else if (!isActive && this.wasActive) {
                if (progress === 1) {
                    this.runListener('toggle', 'leave')
                } else if (progress < 1) {
                    this.runListener('toggle', 'leaveBack')
                }
            }
        }

        // toggle listeners, but only when "force" is true (e.g. on first run after page load)
        if (force && progress > 0 && progress < 1) {
            this.runListener('toggle', 'enter')
        } else if (force && progress === 1) {
            this.runListener('toggle', 'leave')
        }

        if (progress !== null) this.lastProgress = progress;
        this.wasActive = isActive;
    }

    resolveBoundary(boundaryName, boundary, rect, relativeValue = null) {

        if (typeof boundary === 'number' || (typeof boundary === 'string' && boundary.match(/^\d+$/))) {
            return parseInt(boundary + relativeValue);
        }

        if (rect instanceof DOMRect && typeof boundary === 'string') {

            // for e.g. "top center" or "bottom top" etc.
            const edgeOffsetMatch = boundary.match(/(\w+)\s(\w+)/);
            if (edgeOffsetMatch !== null) {
                const [edge, offset] = [edgeOffsetMatch[1], edgeOffsetMatch[2]];

                // very basic, but will do for now. Add more if needed, or pass in fn to calculate
                if (edge === 'top') {
                    if (offset === 'top') {
                        return rect.y + window.scrollY;
                    }
                    if (offset === 'center') {
                        return rect.y + window.scrollY - (window.innerHeight / 2);
                    }
                }
                if (edge === 'bottom') {
                    if (offset === 'top') {
                        return rect.y + window.scrollY + rect.height;
                    }
                }
            }

            // relative magic
            if (boundary.startsWith('-=') || boundary.startsWith('+=')) {
                const match = boundary.match(/^([+-])=*([\d.]+)(%|px|vh)/);
                if (match !== null) {
                    let [full, plusOrMin, value, unit] = match;
                    value = parseFloat(value);

                    if (unit === '%') {
                        value = rect.height * (value / 100);
                    } else if (unit === 'vh') {
                        value = window.innerHeight * value;
                    } else if (unit === 'px') {
                        // intentionally blank
                    } else {
                        throw new Error(`unit "${unit}" is not supported, but you can add it!`);
                    }

                    // if we're resolving scrollEnd, relative value is the scrollStart value
                    // if we're resolving scrollStart, set relativeValue to triggerEl top position (effectively "top top")
                    if (typeof relativeValue !== 'number') {
                        relativeValue = window.scrollY + rect.y;
                    }

                    return plusOrMin === '-' ? (relativeValue - value) : (relativeValue + value);
                }
                throw new Error(`relative syntax detected, but we couldn't quite make sense of it. check out the code.`);
            }

            console.error('boundary is not supported, but you can add it!')
        }

        // fn can be used to calculate value by its own
        if (typeof boundary === 'function') {
            return boundary(rect);
        }

        return 0;
    }

    runListener(name, meta = null) {
        if (name === 'progress') {
            if (this.progressListeners.length) {
                const evt = {
                    progress: meta,
                };
                this.progressListeners.forEach((fn) => fn(evt))
            }
        } else if (name === 'toggle') {
            if (this.toggleListeners.length) {
                const evt = {
                    dir: meta,
                };
                this.toggleListeners.forEach((fn) => fn(evt))
            }
        } else if (name === 'index') {
            if (this.indexListeners.length) {
                this.indexListeners.forEach((fn) => fn())
            }
        }
    }

    drawMarker(offset) {
        if (!this.isValid()) return;

        const id = `scroll-watcher-${this.uuid}`;
        let el = document.getElementById(id);
        if (!el) {
            document.body.insertAdjacentHTML('beforeend', `<div id="${id}"></div>`);
            el = document.getElementById(id);
        }

        const width = 6;
        const spaceBetween = 4;
        el.style.position = 'absolute';
        el.style.top = `${this.scrollStart}px`;
        el.style.left = `${(width * offset) + (spaceBetween * offset)}px`;
        el.style.height = `${this.scrollEnd - this.scrollStart}px`;
        el.style.minHeight = `${width}px`;
        el.style.width = `${width}px`;
        el.style.background = this.markerColor !== null ? this.markerColor : (markerColors[offset] || markerColors[0]);
        el.style.zIndex = '9999';
    }

    addProgressListener(fn) {
        this.progressListeners.push(fn);
    }

    addToggleListener(fn) {
        this.toggleListeners.push(fn);
    }

    addIndexListener(fn) {
        this.indexListeners.push(fn);
    }

    isValid() {
        return typeof this.scrollStart === 'number' && typeof this.scrollEnd === 'number' && this.scrollStart < this.scrollEnd;
    }

    // Checks if el is within active range
    isActive() {
        const isOut = (this.scrollStart > window.scrollY) || (this.scrollEnd < window.scrollY);
        return !isOut;
    }

    calcScrollProgress() {
        const a = window.scrollY - this.scrollStart;
        const b = window.scrollY - this.scrollEnd;
        const perc = a / (a - b);
        return Math.min(Math.max(perc, 0), 1); // clamp between 0-1
    }

    resolveEl(elOrPath) {
        if (typeof elOrPath === 'string') {
            elOrPath = document.querySelector(elOrPath);
            if (!(elOrPath instanceof HTMLElement)) {
                console.error('Selector was not found');
                return null;
            }
        }
        return elOrPath instanceof HTMLElement ? elOrPath : null;
    }
}

export default ScrollWatcher;
