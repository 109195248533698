import Lenis from '@studio-freight/lenis';

class SWScroll {
    constructor() {
        console.log('%cswScroll', 'background: #222; color: #bada55');
        this.isSafari = false;
        this.elements = {};
        this.parallaxElements = [];
        this.sidescrollElements = [];
        this.scrollTriggerElements = [];
        this.scrollFunctions = [];
        this.mouseParallaxElements = [];
        this.mouseMoveFunctions = [];
        this.cursorElements = {};
        this.winHeight = -1;
        this.winWidth = -1;
        this.mouseX = -1;
        this.mouseY = -1;
        this.mouseXPercent = 0.5;
        this.mouseYPercent = 0.5;
        this.scrollObserverOptions = {
            root: document,
            rootMargin: '0px 0px -5% 0px',
            thresholds: [0.1, 0.25, 0.5, 1]
        };
        // safari
        const ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') != -1) {
            if (ua.indexOf('chrome') > -1) {
                // Chrome
            } else {
                this.isSafari = true; // Safari
                this.scrollObserverOptions.root = null;
            }
        }
        if (ua.indexOf('edge') > -1) {
            this.scrollObserverOptions.root = null;
        }
        // initialize
        this.initMouse();
        this.initResize();
        this.initScroll();
        this.initObserver();
        this.initClick();
    }

    initMouse() {
        window.addEventListener('mousemove', function (e) {
            this.mouseMove(e);
        }.bind(this));
        [...document.querySelectorAll('[data-mouseparallax]')].forEach((el) => {
            const element = {
                dom: el,
                params: el.dataset.mouseparallax,
                distance: 40,
                speed: [0, 0],
                current: [0, 0],
                target: [0, 0]
            };
            if (element.params.indexOf(';') > -1) {
                const params = element.params.split(';');
                element.speed[0] = parseFloat(params[0]);
                element.speed[1] = parseFloat(params[1]);
            } else {
                element.speed[0] = element.speed[1] = parseFloat(element.params);
            }
            this.mouseParallaxElements.push(element);
        });
        const mouseParallaxLoop = function () {
            this.mouseParallaxElements.forEach((el) => {
                if (el.current[0] !== el.target[0] || el.current[1] !== el.target[1]) {
                    el.current[0] = this.lerp(el.current[0], el.target[0], 0.1).toFixed(2);
                    el.current[1] = this.lerp(el.current[1], el.target[1], 0.1).toFixed(2);
                    el.dom.style.transform = 'translate(' + el.current[0] + 'px, ' + el.current[1] + 'px)';
                }
            });
            requestAnimationFrame(mouseParallaxLoop);
        }.bind(this);
        if (this.mouseParallaxElements.length) {
            requestAnimationFrame(mouseParallaxLoop);
        }
    }

    initResize() {
        window.addEventListener('resize', function () {
            this.resize();
        }.bind(this));
        this.resize();
    }

    initObserver() {
        // create observer
        this.scrollObserver = new IntersectionObserver(function (elements) {
            this.observe(elements);
        }.bind(this), this.scrollObserverOptions);
        // query existing images
        [...document.querySelectorAll('[data-viewjs]')].forEach((el) => {
            if (!el.dataset.fadeid) {
                el.dataset.fadeid = this.uuidv4();
                this.addScrollObserver(el);
            }
        });
    }

    initScroll() {
        this.scrollTop = -1;
        // scroll event listener
        // window.addEventListener('scroll', function (e) {
        //     this.scroll();
        // }.bind(this));
        this.lenis = new Lenis({
            lerp: 0.1,
            smooth: true
        });

        // add data-parallax elements
        [...document.querySelectorAll('[data-parallax]')].forEach(el => {
            this.addParallaxElement(el);
        });
        // add data-scrollfit elements
        [...document.querySelectorAll('[data-scrollfit]')].forEach(el => {
            this.addScrollfitElement(el);
        });
        // add data-scroll and data-scroll-function elements
        [...document.querySelectorAll('[data-scroll],[data-scrolljs]')].forEach(el => {
            this.addScrollTriggerElement(el);
        });
        // this.scrollTop = 0;
        // this.scroll();

        //get scroll value
        this.lenis.on('scroll', ({ scroll, limit, velocity, direction, progress }) => {
            // console.log({ scroll, limit, velocity, direction, progress })
            // on scroll set var and call all registered functions
            if (scroll !== this.scrollTop) {
                // console.log('lenis scroll');
                // console.time('parallax');
                this.scrollTop = scroll;
                this.scroll();
                // console.timeEnd('parallax');
            }
        });
        const raf = (time) => {
            this.lenis.raf(time);
            requestAnimationFrame(raf);
        };
        requestAnimationFrame(raf);

        this.scrollTop = window.scrollY || document.body.scrollTop;
        this.scroll();
    }

    initClick() {
        [...document.querySelectorAll('[data-click],[data-clickjs]')].forEach(el => {
            el.addEventListener('click', function (e) {
                e.preventDefault();
                if (el.dataset.click) {
                    el.classList.add(el.dataset.click);
                }
                if (el.dataset.clickjs) {
                    eval(el.dataset.clickjs);
                }
            }.bind(this));
        });
    }

    mouseMove(e) {
        if (e.clientX !== this.mouseX || e.clientY !== this.mouseY) {
            this.mouseX = e.clientX;
            this.mouseY = e.clientY;
            if (this.winWidth > -1 && this.winHeight > -1) {
                this.mouseXPercent = this.mouseX / this.winWidth - 0.5;
                this.mouseYPercent = this.mouseY / this.winHeight - 0.5;
            }
            this.mouseParallaxElements.forEach(el => {
                el.target[0] = (el.speed[0] * el.distance * this.mouseXPercent).toFixed(2);
                el.target[1] = (el.speed[1] * el.distance * this.mouseYPercent).toFixed(2);
            });
            this.mouseMoveFunctions.forEach(fn => fn());
        }
    }

    /*
    called by window event 'scroll' and after resize
    */
    scroll(force = false) {
        this.scrollFunctions.forEach(fn => fn());
        this.parallax();
        this.sidescroll();
        this.scrollTrigger();
        // const scrollY = window.scrollY || document.body.scrollTop;
        // // on scroll set var and call all registered functions
        // if (scrollY !== this.scrollTop || force) {
        //     // console.log('scroll', force);
        //     console.log('native scroll');
        //     this.scrollTop = scrollY;
        //     this.scrollFunctions.forEach(fn => fn());
        //     this.parallax();
        //     this.sidescroll();
        //     this.scrollTrigger();
        // }
    }

    resize() {
        const winWidth = window.innerWidth;
        const winHeight = window.innerHeight;
        if (winWidth !== this.winWidth || winHeight !== this.winHeight) {
            this.winWidth = winWidth;
            this.winHeight = winHeight;
            this.scroll();
        }
    }

    // function called by observer
    observe(elements) {
        // loop over elements, if intersecting we preload
        elements.forEach(function (element) {
            if (element.isIntersecting) {
                this.intersect(element);
            } else {
                this.unintersect(element);
            }
        }.bind(this));
    }

    intersect(element) {
        setTimeout(() => {
            element.target.classList.add('view');
            if (element.target.dataset.view) {
                element.target.classList.add(element.target.dataset.view);
            }
            if (element.target.dataset.viewjs) {
                try {
                    eval(element.target.dataset.viewjs);
                } catch (e) {
                    console.log(e);
                }
            }
        }, 30);
    }

    unintersect(element) {
        element.target.classList.remove('view');
        if (element.target.dataset.view) {
            element.target.classList.remove(element.target.dataset.view);
        }
        if (element.target.dataset.viewjsOut) {
            try {
                eval(element.target.dataset.viewjsOut);
            } catch (e) {
                console.log(e);
            }
        }
    }

    /* generate UUID */
    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    lerp(min, max, fraction) {
        return (parseFloat(max) - parseFloat(min)) * fraction + parseFloat(min);
    }

    // add observer element
    addScrollObserver(element) {
        const t = this;
        const el = {
            element: element,
            headline: element.dataset.viewjs && element.dataset.viewjs.indexOf('Headline') > -1 ? () => {
                // this.fadeInHeadline({target: element});
            } : null
        };
        this.elements[element.dataset.fadeid] = el;
        this.scrollObserver.observe(element);
    }

    removeScrollObserver(element) {
        this.scrollObserver.unobserve(element);
    }

    addOnScroll(fn) {
        this.scrollFunctions.push(fn);
    }

    getParentBySelector(el, selector) {
        let parent = el.parentElement;
        let foundParent = false;
        while (!foundParent) {
            if (parent.classList.contains(selector)) {
                foundParent = true;
            } else {
                if (parent.type !== 'HTML') {
                    // get to the next parent element
                    parent = parent.parentElement;
                } else {
                    // found no parent with the selector, we use the first parent
                    parent = el.parentElement;
                    foundParent = true;
                }
            }
        }
        return parent;
    }

    addScrollfitElement(el) {
        if (el.dataset.scrollfituuid) {
            return;
        }
        el.dataset.scrollfituuid = this.uuidv4();
        // find closest parent with .scrollfit__parent
        const parent = el.closest('.scrollfit__parent') || el.parentElement;
        el.classList.add('will-transform');
        const data = {
            el: el,
            parent: parent,
            bounding: parent.getBoundingClientRect(),
            boundingSelf: el.getBoundingClientRect(),
            directParent: el.parentElement,
            intersecting: false,
            onlyTop: false,
            speed: 1,
            mobile: true,
            scrollfit: true
        };
        this.parallaxElements.push(data);
    }

    addParallaxElement(el) {
        if (el.dataset.parallaxuuid) {
            return;
        }
        el.dataset.parallaxuuid = this.uuidv4();
        let parent = el.parentElement;
        if (el.dataset.parallaxParent) {
            parent = this.getParentBySelector(el, el.dataset.parallaxParent);
        }
        el.classList.add('will-transform');
        this.parallaxElements.push({
            el: el,
            parent: parent,
            bounding: parent.getBoundingClientRect(),
            onlyTop: el.dataset.parallaxTop || false,
            speed: parseFloat(el.dataset.parallax),
            setOpacity: el.dataset.parallaxOpacity || false,
            speedMobile: el.dataset.parallaxMobile ? parseFloat(el.dataset.parallaxMobile) : parseFloat(el.dataset.parallax),
            mobile: el.dataset.parallaxMobile ? el.dataset.parallaxMobile : false || false
        });
    }

    isInViewport(bounding) {
        return bounding.top <= this.winHeight * 1.1 && bounding.top + bounding.height > -50;
    }

    // move the item relative to the parent
    parallax() {
        this.parallaxElements.forEach(item => {
            const bounding = item.parent.getBoundingClientRect();
            // if a scrollfit element
            if (this.isInViewport(bounding)) {
                if (item.scrollfit) {
                    const boundingSelf = item.el.getBoundingClientRect();
                    const boundingParent = item.directParent.getBoundingClientRect();
                    const heightDifference = boundingSelf.height - boundingParent.height;
                    // const heightDifference = item.el.clientHeight - item.parent.clientHeight;
                    // we only need this magic, if the el is higher than it's parent
                    if (heightDifference > 0) {
                        const scrollDistance = bounding.height - this.winHeight; // the distance we need to scroll
                        // const scrollDistance = item.parent.clientHeight - this.winHeight; // the distance we need to scroll
                        // scrollprogress starts at bounding.top < viewport top, ends at bounding bottom edge at either viewport top or viewport bottom. depends on style factor, may change
                        const scrollProgress = bounding.top < 0 ? Math.min(-bounding.top / scrollDistance, 1) : 0; // the progress
                        // const scrollProgress = boundingTop < 0 ? Math.min(-boundingTop / scrollDistance, 1) : 0; // the progress
                        // now we need to translate the item.el depending on the scroll progress
                        const translate = heightDifference / 2 - (heightDifference * scrollProgress);
                        item.el.style.transform = `translate3d(0, ${translate}px, 0)`;
                    } else {
                        item.el.style.transform = 'translate3d(0, 0, 0)';
                    }
                } else {
                    let bTop = bounding.top;
                    if (item.onlyTop) {
                        if (bTop > 0) {
                            bTop = 0;
                        }
                    }
                    let top = (bTop + bounding.height / 2) - this.winHeight / 2;
                    // if (this.winWidth < 768 && !item.mobile) {
                    //     top = 0;
                    // }
                    item.el.style.transform = 'translate3d(0, ' + (top * (this.winWidth < 768 ? item.speedMobile : item.speed)) + 'px, 0)';
                    if (item.setOpacity) {
                        // console.log(factor);
                        item.el.style.opacity = Math.max(0, Math.min(1, 1 + (top + bounding.height - this.winHeight) / this.winHeight));
                    }
                }
            }
        });
    }

    // side scroll elements
    sidescroll() {
        this.sidescrollElements.forEach(item => {
            const bounding = item.parent.getBoundingClientRect();
            if (this.isInViewport(bounding)) {
                const bTop = bounding.top * -1;
                const bHeight = bounding.height;
                const sectionHeight = bHeight - this.winHeight;
                let scrollProgress = bTop / sectionHeight;
                if (scrollProgress < 0) {
                    scrollProgress = 0;
                }
                if (scrollProgress > 1) {
                    scrollProgress = 1;
                }
                const translate = (item.flex.scrollWidth - this.winWidth) * (scrollProgress);
                item.flex.style.transform = `translate3d(${-translate}px, 0, 0)`;
            }
        });
    }

    addScrollTriggerElement(el, fn = null, fnOut = null) {
        if (el.dataset.scrolluuid) {
            return;
        }
        el.dataset.scrolluuid = this.uuidv4();
        let parent = el;
        if (el.dataset.scrollParent) {
            parent = this.getParentBySelector(el, el.dataset.scrollParent);
        }
        this.scrollTriggerElements.push({
            el: el,
            parent: parent,
            bounding: parent.getBoundingClientRect(),
            section: this.getParentBySelector(el,'module-section'),
            class: el.dataset.scroll || null,
            fn: fn ? fn : (el.dataset.scrolljs || null),
            fnOut: fnOut ? fnOut : (el.dataset.scrolljsout || null),
            anchor: typeof el.dataset.scrollAnchor !== 'undefined' ? parseFloat(el.dataset.scrollAnchor) : 0.5,
            once: typeof el.dataset.scrollOnce !== 'undefined' && el.dataset.scrollOnce === 'true',
            triggered: false,
            triggerCount: 0
        });
    }

    scrollTrigger() {
        this.scrollTriggerElements.forEach(item => {
            const sectionBounding = item.section.getBoundingClientRect();
            if (this.isInViewport(sectionBounding)) {
                const bounding = item.parent.getBoundingClientRect();
                const top = (bounding.top + bounding.height * item.anchor) - this.winHeight / 2;
                if (bounding.top <= 0 || top <= 0) {
                    if (!item.triggered) {
                        item.triggerCount++;
                        if (!item.once || (item.once && item.triggerCount === 1)) {
                            item.triggered = true;
                            if (item.class) {
                                item.el.classList.add(item.class);
                            }
                            if (item.fn) {
                                if (typeof item.fn === 'function') {
                                    item.fn();
                                } else {
                                    const exec = function () {
                                        eval(item.fn);
                                    }.bind(this);
                                    exec();
                                }
                            }
                        }
                    }
                } else {
                    if (item.triggered) {
                        item.triggered = false;
                        if (item.class && !item.once) {
                            item.el.classList.remove(item.class);
                        }
                        if (item.fnOut) {
                            if (typeof item.fnOut === 'function') {
                                item.fnOut();
                            } else {
                                const exec = function () {
                                    eval(item.fnOut);
                                }.bind(this);
                                exec();
                            }
                        }
                    }
                }
            }
        });
    }
};

/* special js animations */
class SWEffects extends SWScroll {
    constructor() {
        super();
        this.easing = {
            // no easing, no acceleration
            linear: t => t,
            // accelerating from zero velocity
            easeInQuad: t => t * t,
            // decelerating to zero velocity
            easeOutQuad: t => t * (2 - t),
            // acceleration until halfway, then deceleration
            easeInOutQuad: t => t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t,
            // accelerating from zero velocity
            easeInCubic: t => t * t * t,
            // decelerating to zero velocity
            easeOutCubic: t => (--t) * t * t + 1,
            // acceleration until halfway, then deceleration
            easeInOutCubic: t => t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
            // accelerating from zero velocity
            easeInQuart: t => t * t * t * t,
            // decelerating to zero velocity
            easeOutQuart: t => 1 - (--t) * t * t * t,
            // acceleration until halfway, then deceleration
            easeInOutQuart: t => t < .5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t,
            // accelerating from zero velocity
            easeInQuint: t => t * t * t * t * t,
            // decelerating to zero velocity
            easeOutQuint: t => 1 + (--t) * t * t * t * t,
            // acceleration until halfway, then deceleration
            easeInOutQuint: t => t < .5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t,
            // elastic bounce effect at the beginning
            easeInElastic: t => (.04 - .04 / t) * Math.sin(25 * t) + 1,
            // elastic bounce effect at the end
            easeOutElastic: t => .04 * t / (--t) * Math.sin(25 * t),
            // elastic bounce effect at the beginning and end
            easeInOutElastic: t => (t -= .5) < 0 ? (.02 + .01 / t) * Math.sin(50 * t) : (.02 - .01 / t) * Math.sin(50 * t) + 1
        };
        const main = document.querySelector('main');
        if (main && !main.classList.contains('backendmode')) {
            this.initCursor();
        }
        this.initMenu();
        this.initDocument();
    }

    // Headline fade-in effect
    fadeInHeadline(el) {
        const headlines = el.target.querySelectorAll('.text-animation');
        if (!headlines.length) {
            return;
        }
        const headline = headlines[0];
        headline.classList.remove('view');
        // if not already saved, save the original text (else SplitText would replicate the content on a new trigger)
        if (!headline.dataset.animationtext) {
            headline.dataset.animationtext = headline.innerHTML;
        }
        // each word in a span
        const words = headline.dataset.animationtext.split(' ');
        let wordsOutput = '';
        words.forEach(word => {
            wordsOutput += '<span class="text-animation__word">' + word + ' </span>';
        });
        headline.innerHTML = wordsOutput;
        const linesByOffset = {};
        const lines = {};
        let lineNumber = 0;
        [...headline.querySelectorAll('.text-animation__word')].forEach(word => {
            const top = word.offsetTop;
            if (!(top in linesByOffset)) {
                lineNumber++;
                linesByOffset[top] = '';
                lines[lineNumber] = '';
            }
            lines[lineNumber] += word.innerHTML;
        });
        let htmlOutput = '';
        Object.keys(lines).forEach((line) => {
            htmlOutput += '<div class="text-animation__line"><div class="text-animation__line__inner">' + lines[line] + '</div></div>';
        });
        headline.innerHTML = htmlOutput;
        setTimeout(() => {
            headline.classList.add('view');
        }, 100);
    }

    // number counter
    counter(el, startNumber = 0, endNumber = 0, duration = 2000, delay = 0, easingFunction = 'easeInOutCubic') {
        if (el.target.dataset.counterActive) {
        } else {
            if (startNumber !== endNumber) {
                easingFunction = easingFunction in this.easing ? easingFunction : 'easeInOutCubic';
                el.target.dataset.counterActive = true;
                const numberFormat = new Intl.NumberFormat('de-DE', {style: 'decimal'});
                el.target.innerHTML = numberFormat.format(startNumber);
                let startDate;
                const tick = function () {
                    const now = new Date();
                    const delta = now - startDate;
                    let ease = this.easing[easingFunction](delta / duration);
                    if (delta > duration) {
                        cancelAnimationFrame(tick);
                        ease = 1;
                        delete el.target.dataset.counterActive;
                    } else {
                        requestAnimationFrame(tick);
                    }
                    const value = Math.floor(endNumber * ease);
                    el.target.innerHTML = numberFormat.format(value);
                }.bind(this);
                setTimeout(function () {
                    startDate = new Date();
                    requestAnimationFrame(tick);
                }.bind(this), delay);
            }
        }
    }

    intersect(element) {
        setTimeout(function () {
            element.target.classList.add('view');
            if (element.target.dataset.view) {
                element.target.classList.add(element.target.dataset.view);
            }
            if (element.target.dataset.viewjs) {
                try {
                    eval(element.target.dataset.viewjs);
                } catch (e) {
                    console.log(e);
                }
            }
        }.bind(this), 30);
    }

    embed(dom) {
        const regex = /\[\/?(?:embed){1,}.*?]/mgi;
        let html = dom.innerHTML;
        const embeds = regex.exec(html);
        if (embeds && embeds.length) {
            embeds.forEach((found) => {
                if (found.indexOf('=') > -1) {
                    let url = found.substr(found.indexOf('=') + 1);
                    url = url.substr(0, url.indexOf(']')).trim();
                    const newHtml = `<div class="vue-app"><embed-social url="${url}"></embed-social></div>`;
                    html = html.replace(found, newHtml);
                }
            });
            dom.innerHTML = html;
        }
    }

    initCursor() {
        this.cursorDom = document.getElementById('cursor');
        // console.log('initCursor', this.cursorDom);
        if (this.cursorDom) {
            this.mouseMoveFunctions.push(() => {
               this.cursorDom.style.top = `${this.mouseY}px`;
               this.cursorDom.style.left = `${this.mouseX}px`;
            });
            // hovers etc
            [...document.querySelectorAll('[data-cursor]')].forEach((el) => {
               el.addEventListener('mouseenter', () => {
                    this.changeCursor(el, true);
               });
                el.addEventListener('mouseleave', () => {
                    this.changeCursor(el, false);
                });
            });
        }
    }

    changeCursor(el, enter) {
        if (!el.dataset.cursor) {
            return;
        }
        const cursorClass = el.dataset.cursor.split(' ');
        if (enter) {
            cursorClass.forEach((cls) => {
                if (cls === 'audio' && el.nodeName.toLowerCase() === 'video') {
                    if (el.muted) {
                        this.cursorDom.classList.add('cursor--audio--on');
                        this.cursorDom.classList.remove('cursor--audio--off');
                    } else {
                        this.cursorDom.classList.add('cursor--audio--off');
                        this.cursorDom.classList.remove('cursor--audio--on');
                    }
                }
                this.cursorDom.classList.add(`cursor--${cls}`);
            });
        } else {
            cursorClass.forEach((cls) => {
                this.cursorDom.classList.remove(`cursor--${cls}`);
            });
        }
    }

    initMenu() {
        const header = document.querySelector('header');
        if(!header) return;
        this.menu = {
            header: header,
            burger: header.querySelector('.burger'),
            social: header.querySelector('.social'),
            content: document.querySelector('nav'),
            closeArea: document.querySelector('.menu__closearea'),
            title: {
                dom: header.querySelector('.websitetitle'),
                domPage: header.querySelector('.pagetitle'),
                lastScrollY: 0,
                visible: true
            },
            open: false,
            toggle: () => {
                if (this.menu.open) {
                    this.menu.open = false;
                    this.menu.header.classList.remove('open');
                    this.menu.content.classList.remove('open');
                    this.menu.burger.classList.remove('open');
                } else {
                    this.menu.open = true;
                    this.menu.header.classList.add('open');
                    this.menu.content.classList.add('open');
                    this.menu.burger.classList.add('open');
                }
            }
        };
        this.menu.burger.addEventListener('click', (e) => {
           e.preventDefault();
           this.menu.toggle();
        });
        this.menu.closeArea.addEventListener('click', (e) => {
            e.preventDefault();
            this.menu.toggle();
        });
        this.menu.content.addEventListener('scroll', (e) => {
            e.stopPropagation();
        });
        document.body.addEventListener('keyup', (e) => {
            // console.log(e.code);
            if (this.menu.open && e.code === 'Escape') {
                this.menu.toggle();
            }
        });
        // page title
        this.scrollFunctions.push(() => {
           if (this.menu.title.visible) {
               if (this.scrollTop > this.menu.title.lastScrollY) {
                   this.menu.title.visible = false;
                   this.menu.title.dom.classList.remove('view');
                   this.menu.title.domPage.classList.remove('view');
                   this.menu.social.classList.remove('view');
               }
           } else {
               if (this.scrollTop < this.menu.title.lastScrollY) {
                   this.menu.title.visible = true;
                   this.menu.title.dom.classList.add('view');
                   this.menu.social.classList.add('view');
               }
           }
           if (this.scrollTop < 50) {
               if (this.menu.title.visible) {
                   this.menu.title.domPage.classList.add('view');
                   this.menu.title.dom.classList.remove('view');
               }
           }
           this.menu.title.lastScrollY = this.scrollTop * 1;
        });
    }

    initDocument() {
        [...document.querySelectorAll('[class*=\'view:\'],[class*=\'fade-in\'],[class*=\'group-observe\']')].forEach((el) => {
            // get parent
            const isInSlider = el.closest('.swiper-slide') ? true : false;
            if (isInSlider) {
                // console.log(el, 'add class');
                el.classList.add('view');
            } else {
                if ((el.classList.contains('fade-in') || el.classList.contains('group-observe') || el.className.indexOf('view:') > -1) && !el.dataset.observed) {
                    el.dataset.observed = 'true';
                    // console.log('observe', el);
                    this.addScrollObserver(el);
                }
            }
        });

        // // search replace embed code
        [...document.querySelectorAll('.textcontent')].forEach((dom) => {
            this.embed(dom);
        });
        // // hide embed code in news overview
        [...document.querySelectorAll('.article p')].forEach((dom) => {
            const regex = /\[\/?(?:embed){1,}.*?]/mgi;
            const found = regex.exec(dom);
            if (found.length) {
                found.forEach((found) => {
                    // console.log('remove p', found.innerHTML);
                    found.remove();
                });
            }
        });

        // accordion
        [...document.querySelectorAll('.accordion')].forEach((dom) => {
            const elements = dom.querySelectorAll('.accordion__element');
            [...elements].forEach((element) => {
                let isOpen = element.classList.contains('open');
                const title = element.querySelector('.accordion__title');
                title.addEventListener('click', (e) => {
                    e.preventDefault();
                    if (isOpen) {
                        element.classList.remove('open');
                        isOpen = false;
                    } else {
                        element.classList.add('open');
                        isOpen = true;
                    }
                });
            });
        });

        [...document.querySelectorAll('.sidescroll')].forEach((dom) => {
            this.sidescrollElements.push({
                parent: dom,
                flex: dom.querySelector('.flex')
            });
        });
    }
};

export default SWEffects;
