document.querySelectorAll('.framed-video-module').forEach((moduleEl) => {
    const colorEl = moduleEl.querySelector('[data-color-change-config]');
    let colorConf = [];
    if (colorEl) {
        const conf = JSON.parse(colorEl.dataset.colorChangeConfig);
        if (conf.length) {
            colorConf = conf.map((change, index) => {
                change.from = parseInt(change.from); // + (conf.hasOwnProperty(index - 1) ? conf[index - 1].from : 0);
                return change;
            }).reverse();
        }
    }

    // only relevant for tablet frame
    const videoZoomEl = moduleEl.querySelector('[data-fill-viewport-after]');
    const videoZoomAt = videoZoomEl ? parseInt(videoZoomEl.dataset.fillViewportAfter) : null;
    let videoHasZoomed = false;

    window.addEventListener('resize', () => {
        if (videoHasZoomed) {
            applyZoomEffect(true);
        }
    })

    const applyZoomEffect = (isCorrection = false) => {
        if (isCorrection) {
            videoZoomEl.style.transitionDuration = null;
            videoZoomEl.style.transform = null;
        }
        const rect = videoZoomEl.querySelector('video').getBoundingClientRect();
        const zoomX = window.innerWidth / rect.width;
        const zoomY = window.innerHeight / rect.height;
        if (!isCorrection) {
            videoZoomEl.style.transitionDuration = '2s';
        }
        videoZoomEl.style.transform = `scale(${Math.max(zoomX, zoomY)}) translateY(3.5%)`;
    }
    const onVideoProgress = (e) => {
        // handle bg color changing
        if (colorEl && colorConf.length) {
            for (const change of colorConf) {
                if (e.target.currentTime >= change.from) {
                    colorEl.style.background = change.color;
                    break;
                }
            }
        }

        // handle video zoom to viewport
        if (videoZoomEl && !videoHasZoomed) {
            if (e.target.currentTime >= videoZoomAt) {
                applyZoomEffect();
                videoHasZoomed = true;
            }
        }
    }
    const resetModule = () => {
        // reset videos on out
        moduleEl.querySelectorAll('video').forEach((video) => {
            video.pause();
            video.currentTime = 0;
        })

        if (colorEl) {
            colorEl.style.background = colorEl.dataset.defaultColor;
        }
        if (videoZoomEl) {
            videoZoomEl.style.transform = null;
            videoZoomEl.style.transitionDuration = null;
            videoHasZoomed = false;
        }
    }

    const obs = new IntersectionObserver((entries) => {
        const entry = entries[0];

        if (entry.isIntersecting && entry.intersectionRatio > .4) {
            // play/restart videos
            moduleEl.querySelectorAll('video').forEach((video, index) => {
                video.play();

                if (index === 0) {
                    // color change interval resets on video-end, so videos must have the same lengths
                    video.removeEventListener('ended', resetModule);
                    video.addEventListener('ended', resetModule);

                    video.removeEventListener('timeupdate', onVideoProgress);
                    video.addEventListener('timeupdate', onVideoProgress);
                }
            });
        } else if (!entry.isIntersecting) {
            // reset styles
            resetModule();
        }
    }, { threshold: [0, .4] })
    obs.observe(moduleEl);
});
