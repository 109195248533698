import ScrollWatcher from '../ScrollWatcher';

class SplitArrow {
    constructor() {
        this.t1 = null;
    }

    init() {
        document.querySelectorAll('.split-arrow-module').forEach((moduleEl, index) => {
            // desktop
            const arrow = moduleEl.querySelector('.arrow-anim');
            const text1 = moduleEl.querySelector('.text-anim-1');
            const text2 = moduleEl.querySelector('.text-anim-2');
            const icon = moduleEl.querySelector('.icon-anim');
            const videoHolder = moduleEl.querySelector('.video-holder');
            const videoEl = videoHolder.querySelector('.video');
            const videoElMobile = moduleEl.querySelector('.video-mobile');
            const arrowMobile = moduleEl.querySelector('.arrow-mobile-el');

            // desktop
            new ScrollWatcher({
                triggerEl: moduleEl.querySelector('.arrow-anim-trigger'),
                onProgress(e) {
                    arrow.style.transform = `translateX(-${200 * (e.progress)}vw)`;
                    arrow.style.opacity = Math.min(e.progress * 15, 1);
                },
            })

            new ScrollWatcher({
                triggerEl: moduleEl.querySelector('.text-1-anim-trigger'),
                onProgress(e) {
                    text1.style.transform = `translateX(${-200 * (e.progress - 1)}px)`;
                    text1.style.opacity = e.progress;
                },
            })

            new ScrollWatcher({
                triggerEl: moduleEl.querySelector('.text-2-anim-trigger'),
                onProgress(e) {
                    text2.style.transform = `translateX(${-200 * (e.progress - 1)}px)`;
                    text2.style.opacity = e.progress;
                },
            })

            new ScrollWatcher({
                triggerEl: moduleEl.querySelector('.video-anim-trigger'),
                onProgress(e) {
                    videoEl.style.filter = `blur(${e.progress * 50}px)`;
                    videoEl.style.transform = `scale(${1 + (e.progress * .3)})`;
                    videoEl.style.opacity = `${1 - e.progress}`;
                },
            })

            if (icon) {
                new ScrollWatcher({
                    triggerEl: moduleEl.querySelector('.icon-anim-trigger'),
                    onProgress(e) {
                        if (e.progress < .15) {
                            icon.style.opacity = e.progress * 8.5;
                        } else if (e.progress < .85) {
                            icon.style.opacity = 1;
                        } else {
                            icon.style.opacity = (1 - e.progress) * 8.5;
                        }
                    },
                })
            }


            // mobile
            if (arrowMobile && videoElMobile) {
                new ScrollWatcher({
                    start() {
                        return arrowMobile.getBoundingClientRect().y + window.SWScroll.scrollTop - window.SWScroll.winHeight;
                    },
                    end() {
                        return arrowMobile.getBoundingClientRect().y + window.SWScroll.scrollTop + (window.SWScroll.winHeight / 2);
                    },
                    onProgress(e) {
                        videoElMobile.style.opacity = (1 - e.progress) / 2;
                    },
                })

                // new ScrollWatcher({
                //     triggerEl: arrowMobile,
                //     onProgress(e) {
                //         const str = `linear-gradient(${(360 * e.progress).toFixed(2)}deg, #000, #1023FF 80%)`;
                //         arrowMobile.style.background = str;
                //     },
                // })
            }


            // text blocks
            moduleEl.querySelectorAll('.text-block-trigger').forEach((triggerEl, index) => {
                window.SWScroll.addScrollTriggerElement(triggerEl, () => {
                    this.showTextBlock(triggerEl, parseInt(triggerEl.dataset.textIndex) + 1, triggerEl.dataset.textGroup);
                }, () => {
                    this.showTextBlock(triggerEl, parseInt(triggerEl.dataset.textIndex), triggerEl.dataset.textGroup);
                })
            });
        });
    }

    showTextBlock(triggerEl, showIndex, group) {
        const moduleEl = triggerEl.closest('.split-arrow-module');
        const textBlocks = moduleEl.querySelectorAll(`.text-block[data-text-group="${group}"]`);

        console.log('showTextBlock', showIndex, group);

        clearTimeout(this.t1);
        textBlocks.forEach((el, index) => {
            el.style.opacity = null;
            if (index === showIndex) {
                this.t1 = setTimeout(() => {
                    el.classList.add('animate-fade-in');
                    el.classList.remove('animate-fade-out');
                }, 500) // match anim duration specified in template
            } else {
                el.classList.remove('animate-fade-in');
                el.classList.add('animate-fade-out');
            }
        });
    }
};

export default SplitArrow;
