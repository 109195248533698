import debounce from 'lodash/debounce';

const outEffectTimeMs = 500;

document.querySelectorAll('.split-video-module').forEach((moduleEl, moduleIndex) => {
    let orientation;
    let videoEl;
    const fillScreenEffectEl = moduleEl.querySelector('[data-effect="fillScreen"]');
    const fillScreenStartTime = fillScreenEffectEl ? parseInt(fillScreenEffectEl.dataset.effectStartTime) : null;
    let fillScreenEffectIsApplied = false;
    let lastVideoTime = -1;

    const timelineDotsHolderEl = moduleEl.querySelector('.timeline-dots-holder');

    const textHolderEl = moduleEl.querySelector('.text-holder');
    let textBlocksConfig = [];
    let currentTextBlock = null;

    const fullVideoHolder = moduleEl.querySelector('.full-video-holder');
    let fullVideoEl;
    const fullVideoTrigger = moduleEl.querySelector('.full-video-play-trigger');

    let fullVideoIsStarted = false;

    const getTextBlocksConfig = () => {
        const cnf = [];
        [...textHolderEl.children].forEach((block, index) => {
            const nextBlock = textHolderEl.children[index + 1];
            cnf.push({
                index: index,
                el: block,
                start: parseFloat(block.dataset.startAt),
                end: block.dataset.endAt ? parseFloat(block.dataset.endAt) : (nextBlock ? parseFloat(nextBlock.dataset.startAt) - (outEffectTimeMs / 1000 * .7) : null),
                isLast: index === textHolderEl.children.length - 1,
            })
        })
        textBlocksConfig = cnf;
    }

    const setVideoEls = () => {
        if (orientation === 'landscape') {
            videoEl = moduleEl.querySelector('.video-landscape');
            fullVideoEl = moduleEl.querySelector('.full-video-landscape');
        } else {
            videoEl = moduleEl.querySelector('.video-portrait');
            fullVideoEl = moduleEl.querySelector('.full-video-portrait');
        }
    }

    const onFullVideoTriggerClick = () => {
        videoEl.pause();
        fullVideoHolder.style.display = 'flex';
        fullVideoIsStarted = true;
        setTimeout(() => fullVideoEl.play(), 300);
    }

    const onFullVideoHolderClick = (e) => {
        if (e.target === fullVideoHolder) {
            fullVideoEl.currentTime = 0;
            fullVideoEl.pause();
            videoEl.play();
            fullVideoHolder.style.display = null;
            fullVideoIsStarted = false;
        }
    }

    const removeEventListeners = () => {
        videoEl.removeEventListener('ended', onSectionLeave);
        videoEl.removeEventListener('timeupdate', onVideoProgress);
        if (fullVideoHolder) {
            fullVideoTrigger.removeEventListener('click', onFullVideoTriggerClick)
            fullVideoHolder.removeEventListener('click', onFullVideoHolderClick)
        }
    }

    const setupEventListeners = () => {
        videoEl.addEventListener('timeupdate', onVideoProgress);
        videoEl.addEventListener('ended', onSectionLeave);
        if (fullVideoHolder) {
            fullVideoTrigger.addEventListener('click', onFullVideoTriggerClick)
            fullVideoHolder.addEventListener('click', onFullVideoHolderClick)
        }
    }

    const resolveTextBlockEnd = (block) => block.end === null ? videoEl.duration + 1 : block.end;

    let t1, t2;
    const onVideoProgress = (e) => {
        const currentTime = e.target.currentTime;

        // fill screen effect
        if (fillScreenEffectEl && fillScreenStartTime && !fillScreenEffectIsApplied) {
            if (currentTime > fillScreenStartTime) {
                fillScreenEffectEl.style.transitionDuration = '2.5s';
                fillScreenEffectEl.style.transitionTimingFunction = 'cubic-bezier(0.160, 0.675, 0.165, 1.000)'; // custom

                // zero all edges for left/right and mobile support
                fillScreenEffectEl.style.left = '0';
                fillScreenEffectEl.style.right = '0';
                fillScreenEffectEl.style.top = '0';
                fillScreenEffectEl.style.bottom = '0';

                // hide text holder
                textHolderEl.style.transitionDuration = '.5s';
                textHolderEl.style.opacity = '0';

                fillScreenEffectIsApplied = true;
            }
        }

        // on loop or backwards scrub
        if (lastVideoTime > currentTime && lastVideoTime !== -1) {
            resetTextBlocks();
        }

        // text blocks
        for (const block of textBlocksConfig) {
            let isLeaving = false;

            // Out anims
            if (currentTextBlock && (resolveTextBlockEnd(currentTextBlock) < currentTime || currentTextBlock.leaveNow)) {
                isLeaving = true;
                const oldEl = currentTextBlock.el;
                if (currentTextBlock.leaveNow || (currentTextBlock.isLast && currentTextBlock.end === null)) {
                    oldEl.style.display = 'none';
                } else {
                    oldEl.style.transitionDuration = `${outEffectTimeMs}ms`;
                    oldEl.style.opacity = 0;
                    t1 = setTimeout(() => {
                        oldEl.style.opacity = null;
                        oldEl.style.transitionDuration = null;
                        oldEl.style.display = 'none';
                    }, outEffectTimeMs)
                }
                currentTextBlock = null;

                const currentDot = timelineDotsHolderEl && timelineDotsHolderEl.querySelector('.dot.current');
                if (currentDot) {
                    currentDot.classList.remove('current');
                    currentDot.style.color = null;
                    currentDot.style.transform = null;
                }
            }

            // In anims
            if (!currentTextBlock || isLeaving) {
                if (block.start < currentTime && resolveTextBlockEnd(block) > currentTime) {
                    // wait for out-anim of current block before animating in
                    t2 = setTimeout(() => {
                        block.el.style.display = 'flex';

                        const lottiePlayer = block.el.querySelector('lottie-player');
                        if (lottiePlayer) {
                            lottiePlayer.seek(0);
                            lottiePlayer.play();
                        }

                        const textScroller = block.el.querySelector('.text-scroller');
                        const delay = 1;
                        textScroller.style.transform = null;
                        textScroller.style.transitionDuration = null;
                        textScroller.style.transitionDelay = null;
                        const textOverflowPx = textScroller.parentElement.scrollHeight - textScroller.parentElement.clientHeight
                        if (textOverflowPx > 0) {
                            const scrollDuration = resolveTextBlockEnd(block) - block.start - delay;
                            textScroller.style.transitionDuration = `${scrollDuration}s`;
                            textScroller.style.transitionDelay = `${delay}s`;
                            textScroller.style.transform = `translateY(-${textOverflowPx}px)`;
                        }
                    }, isLeaving ? outEffectTimeMs * .5 : 0)

                    // avoid mutating original block in textBlocksConfig
                    currentTextBlock = JSON.parse(JSON.stringify(block));
                    currentTextBlock.el = block.el;

                    const newCurrentDot = timelineDotsHolderEl ? timelineDotsHolderEl.querySelectorAll('.dot')[block.index] : null;
                    if (newCurrentDot) {
                        newCurrentDot.classList.add('current');
                        newCurrentDot.style.color = 'white';
                        newCurrentDot.style.transform = 'scale(1.3)';
                    }

                    // found the one we want, stop looking
                    break;
                }
            }
        }

        // text blocks
        lastVideoTime = currentTime;
    };

    const resetTextBlocks = () => {
        // hide whatever block is currently displaying
        if (currentTextBlock && currentTextBlock.end !== null) {
            currentTextBlock.leaveNow = true;
            currentTextBlock.el.style.display = 'none';
        }
        clearTimeout(t1);
        clearTimeout(t2);
        textBlocksConfig.forEach((block) => {
            if (block.end) {
                block.el.style.display = 'none';
                block.el.style.opacity = null;
                block.el.style.transitionDuration = null;
            }
        })
    }

    const onSectionLeave = () => {
        if (fullVideoIsStarted) {
            fullVideoEl.pause();
        } else {
            // reset videos on out
            videoEl.pause();
            videoEl.currentTime = 0;

            // reset text blocks
            resetTextBlocks();

            if (fillScreenEffectIsApplied) {
                fillScreenEffectEl.style = null;
                fillScreenEffectEl.style = null;
                fillScreenEffectEl.style = null;

                textHolderEl.style.transitionDuration = null;
                textHolderEl.style.opacity = null;

                fillScreenEffectIsApplied = false;
            }
        }
    };

    const getOrientation = () => window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
    const setOrientation = () => orientation = getOrientation();

    const onOrientationChange = () => {
        if (fullVideoEl) fullVideoEl.pause();
        removeEventListeners();
        setVideoEls();
        videoEl.currentTime = 0;
        setupEventListeners();
        if (!fullVideoIsStarted) {
            videoEl.play();
        }
    }

    const init = () => {
        if (!textHolderEl) {
            return;
        }

        setOrientation();
        getTextBlocksConfig();
        setVideoEls();
        setupEventListeners();

        const obs = new IntersectionObserver((entries) => {
            const entry = entries[0];

            if (entry.isIntersecting && entry.intersectionRatio > .4) {
                videoEl.play();
            } else if (!entry.isIntersecting) {
                onSectionLeave();
            }
        }, { threshold: [0, .4] })
        obs.observe(moduleEl);

        window.addEventListener('resize', debounce(() => {
            const newOrientation = getOrientation();
            if (orientation !== newOrientation) {
                orientation = newOrientation;
                onOrientationChange();
            }
        }, 200));

        if (timelineDotsHolderEl) {
            timelineDotsHolderEl.addEventListener('click', (e) => {
                const dot = e.target.closest('[data-timeline-trigger]');
                if (dot) {
                    const time = parseFloat(dot.dataset.timelineTrigger);
                    videoEl.currentTime = time;
                }
            })
        }
    }

    init();
});
