const moduleEls = document.querySelectorAll('.client-showcase-module');

import ScrollWatcher from '../ScrollWatcher';

moduleEls.forEach((moduleEl) => {
    const logoHolder = moduleEl.querySelector('.logo-holder');

    new ScrollWatcher({
        triggerEl: moduleEl,
        start: '-=.33vh',
        onToggle(e) {
            if (e.dir === 'enter') {
                logoHolder.style.opacity = null;
                logoHolder.style.transitionDuration = null;
                logoHolder.classList.add('animate-reveal-down');
            }
            if (e.dir === 'leaveBack') {
                logoHolder.style.opacity = '0';
                logoHolder.style.transitionDuration = '.5s';
                logoHolder.classList.remove('animate-reveal-down');
            }
        },
    })

})
