import '../scss/app.scss';
import Intersect from './intersect'; // IntersectionObserver
import SWEffects from './swScroll'; // Scroll Trigger Library, automatic fade in
import SwiperOnPage from './swiper'; // global swiper setup
import anime from 'animejs';
// import SWCurtain from './curtain'; // curtain.js setup
import "@lottiefiles/lottie-player";

import './modules/videoSplit';
import './modules/framedVideo';
import SplitArrow from './modules/splitArrow';
import './modules/clientShowcase';
import Form from './modules/form.js';
import Swiper, { Autoplay } from 'swiper';
Swiper.use([Autoplay]);

/*
this is the global IntersectionObserver
 */
if (!('roObserver' in window)) {
    // @ts-ignore
    window.roObserver = new Intersect({});
}

document.addEventListener('DOMContentLoaded', function (event) {
    console.log('%cMade by SEITWERK', 'background: #222; color: #bada55');
    window.SWScroll = new SWEffects();
    // window.SWCurtain = new SWCurtain();
    /*
    fade-in animations

    simply add .fade-in to an element or
    .group-observe to a parent and group-view: tailwind classes to the children

    examples:

    <div class="fade-in">Test 2</div>

    <div class="group group-observe">
        <div class="group-view:fade-in delay-500 duration-1000">Test</div>
    </div>
     */

    // Videos
    [...document.querySelectorAll('video')].forEach((video) => {
        // image-text timeline update
        let interval;
        let text = [];

        // initial set correct source according to window size
        if (video.dataset.srcMobile) {
            if (window.SWScroll.winWidth < 768) {
                video.src = video.dataset.srcMobile;
            }
            window.addEventListener('resize', () => {
                console.log(window.innerWidth, video.src, video.dataset.srcMobile);
                if (window.innerWidth < 768) {
                    if (video.dataset.srcCurrent !== video.dataset.srcMobile) {
                        video.dataset.srcCurrent = video.dataset.srcMobile;
                        video.src = video.dataset.srcMobile;
                        video.currentTime = 0;
                    }
                } else {
                    if (video.dataset.srcCurrent !== video.dataset.src) {
                        video.dataset.srcCurrent = video.dataset.src;
                        video.src = video.dataset.src;
                        video.currentTime = 0;
                    }
                }
            });
        }

        const hasImageText = video.dataset.text;
        if (hasImageText) {
            const parent = video.parentElement.parentElement; // div.video-timeline__content
            [...parent.querySelectorAll('.video-timeline__text')].forEach((textDom) => {
                const dataset = JSON.parse(textDom.dataset.videotext);
                text.push({
                    dom: textDom,
                    visible: false,
                    hidden: false,
                    start: dataset.start,
                    end: dataset.end,
                });
            });
        }
        const parent = video.parentElement;
        const controlsDom = parent.querySelector('.controls');
        const controls = {
            muted: controlsDom ? controlsDom.querySelector('.muted') : null,
            play: controlsDom ? controlsDom.querySelector('.play') : null,
            replay: controlsDom ? controlsDom.querySelector('.replay') : null,
            fullscreen: controlsDom ? controlsDom.querySelector('.fullscreen') : null,
            changeMuted: () => {
                if (controls.muted) {
                    const i = controls.muted.querySelector('i');
                    i.classList.add(video.muted ? 'icon-speaker' : 'icon-speaker1');
                    i.classList.remove(video.muted ? 'icon-speaker1' : 'icon-speaker');
                }
            }
        };
        if (controlsDom) {
            if (controls.muted) {
                controls.muted.addEventListener('click', (e) => {
                    video.muted = !video.muted;
                    controls.changeMuted();
                    window.SWScroll.changeCursor(video, true);
                });
            }
            if (controls.replay) {
                controls.replay.addEventListener('click', (e) => {
                    video.currentTime = 0;
                    video.play();
                });
            }
            if (controls.fullscreen) {
                controls.fullscreen.addEventListener('click', (e) => {
                    if (video.requestFullscreen) {
                        video.requestFullscreen();
                    } else if (video.mozRequestFullScreen) {
                        video.mozRequestFullScreen();
                    } else if (video.webkitRequestFullscreen) {
                        video.webkitRequestFullscreen();
                    } else if (video.msRequestFullscreen) {
                        video.msRequestFullscreen();
                    }
                    video.muted = false;
                    controls.changeMuted();
                    window.SWScroll.changeCursor(video, true);
                });
            }
        }
        video.addEventListener('fullscreenchange', (e) => {
            if (document.fullscreenElement?.nodeName === 'VIDEO') {
                video.classList.add('fullscreen');
                return true;
            }
            video.classList.remove('fullscreen');
            return false;
        });
        video.addEventListener('play', (e) => {
            if (hasImageText) {
                interval = setInterval(() => {
                    text.forEach((t) => {
                        if (t.start) {
                            if (t.start <= video.currentTime && !t.visible) {
                                t.visible = true;
                                t.dom.classList.add('text--visible');
                                // console.log('set text visible', 139, video.currentTime);
                            }
                            if (t.visible && t.start > video.currentTime) {
                                t.visible = false;
                                t.dom.classList.remove('text--visible');
                                // console.log('remove text visible', 144, video.currentTime);
                            }
                        }
                        if (t.end) {
                            if (t.end === -1) { t.end = video.duration; }
                            if (video.currentTime >= t.end && !t.hidden) {
                                t.hidden = true;
                                t.dom.classList.add('text--hidden');
                                // console.log('set text hidden', 152, video.currentTime);
                            }
                            if (video.currentTime < t.end && t.hidden) {
                                t.hidden = false;
                                t.dom.classList.remove('text--hidden');
                                // console.log('remove text hidden', 157, video.currentTime);
                            }
                        }
                        // console.log(video.currentTime, t.start, t.end, t.visible, t.hidden);
                    });
                }, 10);
            }
        });
        video.addEventListener('pause', (e) => {
            if (hasImageText) {
                clearInterval(interval);
            }
        });
        video.addEventListener('ended', (e) => {
            if (!video.loop) {
                video.dataset.endedNative = 'true';
            }
        });
        video.addEventListener('click', (e) => {
            video.muted = !video.muted;
            controls.changeMuted();
            window.SWScroll.changeCursor(video, true);
        })
        if (video.dataset.autoplay) {
            const loop = video.loop;
            video.dataset.outofbounds = 'true';
            const checkAutoplay = () => {
                const playing = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
                let shouldPlay = false;
                const bounds = video.getBoundingClientRect();
                let isOutOfBounds = bounds.y + bounds.height <= 0 || bounds.y > window.SWScroll.height;
                let endedNative = video.dataset.endedNative && video.dataset.endedNative !== '';

                if (!isOutOfBounds) {
                    if (video.dataset.autoplay === 'viewport' && bounds.y < window.SWScroll.winHeight && bounds.y > window.SWScroll.winHeight * -1) {
                        shouldPlay = true;
                    } else if (video.dataset.autoplay === 'center' && bounds.y <= window.SWScroll.winHeight / 2 && bounds.y > window.SWScroll.winHeight * -1) {
                        shouldPlay = true;
                    } else if (video.dataset.autoplay === 'center' && bounds.y <= 0 && bounds.y > window.SWScroll.winHeight * -1) {
                        shouldPlay = true;
                    } else if (video.dataset.autoplay === 'top' && bounds.y < window.SWScroll.winHeight * .1 && bounds.y > window.SWScroll.winHeight * -1) {
                        shouldPlay = true;
                    }
                } else {
                    if (endedNative) {
                        video.dataset.endedNative = '';
                        endedNative = '';
                    }
                }
                if (shouldPlay && !loop && !playing && !video.dataset.outOfBounds && endedNative) {
                    shouldPlay = false;
                    // console.log('no playing');
                }
                if (shouldPlay && !playing) {
                    // console.log('endedNative', video.dataset.endedNative, video.dataset.outOfBounds, loop);
                    video.play();
                    video.dataset.outOfBounds = '';
                    video.dataset.endedNative = '';
                }
                if (!shouldPlay && playing) {
                    video.pause();
                    if (isOutOfBounds) {
                        video.dataset.outofbounds = 'true';
                    }
                }
            };
            window.SWScroll.addOnScroll(checkAutoplay);
            video.addEventListener('canplay', checkAutoplay);
        }
    });

    [...document.querySelectorAll('.sticky-split')].forEach((dom) => {
        const elements = dom.querySelectorAll('.sticky-split__scrollcontent');
        if (elements) {
            const trigger = dom.querySelectorAll('.sticky-split__scrollcontent__trigger');
            const split = {
                dom: dom,
                elements: elements,
                trigger: trigger,
                active: 0,
            };
            // console.log(split);
            [...trigger].forEach((tr, i) => {
                window.SWScroll.addScrollTriggerElement(tr, () => {
                    [...elements].forEach((_el, _i) => {
                        if (_i < i) {
                            _el.classList.add('out');
                        } else {
                            _el.classList.remove('out');
                        }
                    });
                    elements[i].classList.add('view');
                }, () => {
                    elements[i].classList.remove('out');
                    elements[i].classList.remove('view');
                    if (i > 0) {
                        elements[i - 1].classList.remove('out');
                    }
                });
            });
        }
    });

    [...document.querySelectorAll('.lottie-split')].forEach((dom) => {
        const elements = dom.querySelectorAll('.lottie-split__scrollcontent');
        if (elements) {
            const trigger = dom.querySelectorAll('.lottie-split__scrollcontent__trigger');
            const lottie = [];
            [...elements].forEach((el) => {
               const lottieDom = el.querySelectorAll('lottie-player');
               const _lottieDom = [];
               [...lottieDom].forEach(_dom => {
                  _lottieDom.push(_dom);
               });
               lottie.push(lottieDom);
            });
            const videos = [];
            [...elements].forEach((el) => {
                const videoDom = el.querySelector('video');
                videos.push(videoDom);
            });
            const paginator = dom.querySelectorAll('.paginator > div');
            const split = {
                dom: dom,
                elements: elements,
                trigger: trigger,
                videos: videos,
                lottie: lottie,
                paginator: paginator,
                active: 0,
            };

            // console.log(split);
            [...trigger].forEach((tr, i) => {
                // add click-scroll on paginator dot
                if (split.paginator && split.paginator[i]) {
                    split.paginator[i].addEventListener('click', function () {
                        const bounding = tr.getBoundingClientRect();
                        const top = window.scrollY + bounding.top + (window.innerHeight / 2);
                        const param = { y: window.scrollY };
                        anime({
                            targets: param,
                            y: top,
                            duration: 500,
                            easing: 'easeOutCubic',
                            update: () => {
                                window.scrollTo(0, param.y);
                            }
                        });
                    });
                }
                window.SWScroll.addScrollTriggerElement(tr, () => {
                    let removedOut = false;
                    [...elements].forEach((_el, _i) => {
                        if (_i < i) {
                            _el.classList.add('out');
                            if (split.lottie[_i]) {
                                split.lottie[_i].forEach(_dom => _dom.pause());
                            }
                            setTimeout(() => {
                                if (split.videos[_i] && _el.classList.contains('out')) {
                                    split.videos[_i].pause();
                                }
                            }, 1000);
                        } else {
                            if (_i === i && _el.classList.contains('out')) {
                                if (split.lottie[i]) {
                                    // split.lottie[i].play();
                                    split.lottie[i].forEach(_dom => _dom.play());
                                }
                                if (split.paginator[i]) {
                                    paginator[i].classList.add('active');
                                }
                            }
                            _el.classList.remove('out');
                        }
                    });
                    elements[i].classList.add('view');
                    [...split.paginator].forEach(_el => {
                        _el.classList.remove('active');
                    });
                    if (split.paginator[i]) {
                        split.paginator[i].classList.add('active');
                    }
                    if (split.lottie[i]) {
                        // split.lottie[i].seek(0);
                        split.lottie[i].forEach(_dom => _dom.seek(0));
                        setTimeout(() => {
                            if (elements[i].classList.contains('view') && !elements[i].classList.contains('out')) {
                                // split.lottie[i].play();
                                split.lottie[i].forEach(_dom => _dom.play());
                            }
                        }, 1000);
                    }
                    if (split.videos[i]) {
                        split.videos[i].currentTime = 0;
                        setTimeout(() => {
                            if (elements[i].classList.contains('view') && !elements[i].classList.contains('out')) {
                                split.videos[i].play();
                            }
                        }, 500);
                    }
                }, () => {
                    elements[i].classList.remove('out');
                    elements[i].classList.remove('view');
                    if (split.paginator[i]) {
                        split.paginator[i].classList.remove('active');
                    }
                    if (split.lottie[i]) {
                        // split.lottie[i].pause();
                        split.lottie[i].forEach(_dom => _dom.pause());
                    }
                    if (i > 0) {
                        elements[i - 1].classList.remove('out');
                        if (split.lottie[i - 1].length) {
                            split.lottie[i - 1].forEach(_dom => _dom.seek(0));
                            // split.lottie[i - 1].seek(0);
                            setTimeout(() => {
                                if (elements[i - 1].classList.contains('view') && !elements[i - 1].classList.contains('out')) {
                                    // split.lottie[i - 1].play();
                                    split.lottie[i - 1].forEach(_dom => _dom.play());
                                }
                            }, 500);
                        }
                        if (split.videos[i - 1]) {
                            split.videos[i - 1].currentTime = 0;
                            split.videos[i - 1].play();
                        }
                        if (split.paginator[i - 1]) {
                            split.paginator[i - 1].classList.add('active');
                        }
                    }
                });
            });
        }
    });

    [...document.querySelectorAll('.scrolltrigger-text')].forEach((dom) => {
        const textElements = dom.querySelectorAll('.scrolltrigger-text__text');
        const imageElements = dom.querySelectorAll('.scrolltrigger-text__image');
        const trigger = dom.querySelectorAll('.scrolltrigger-text__trigger');
        if (textElements.length || imageElements.length) {
            [...trigger].forEach((tr) => {
                // the right element (textElement or imageElement) number is in data-el="{{ i }}"
                const targetElement = tr.dataset.target === 'text' ? textElements[tr.dataset.el] : imageElements[tr.dataset.el];
                window.SWScroll.addScrollTriggerElement(tr, () => {
                    if (tr.dataset.target === 'text') {
                        const content = targetElement.querySelector('.scrolltrigger-text__text__content');
                        content.classList.add(tr.dataset.scrolltriggerText === 'start' ? 'text--visible' : 'text--hidden');
                    } else {
                        targetElement.classList.add(tr.dataset.scrolltriggerText === 'start' ? 'view' : 'out');
                    }
                }, () => {
                    if (tr.dataset.target === 'text') {
                        const content = targetElement.querySelector('.scrolltrigger-text__text__content');
                        content.classList.remove(tr.dataset.scrolltriggerText === 'start' ? 'text--visible' : 'text--hidden');
                    } else {
                        targetElement.classList.remove(tr.dataset.scrolltriggerText === 'start' ? 'view' : 'out');
                    }
                });
            });
        }
    });

    [...document.querySelectorAll('.team-scroll')].forEach((dom) => {
        const elements = dom.querySelectorAll('.team-scroll__image');
        if (elements) {
            const trigger = dom.querySelectorAll('.team-scroll__trigger');
            const effect = {
                dom: dom,
                elements: elements,
                trigger: trigger,
                active: -1,
                update: () => {
                    [...effect.elements].forEach((el, elI) => {
                        if (elI === effect.active || elI === effect.active - 1) {
                            el.classList.add('view');
                        } else {
                            el.classList.remove('view');
                        }
                    });
                }
            };
            // console.log(split);
            // we always show 2 active images. i and i - 1
            [...trigger].forEach((tr, i) => {
                window.SWScroll.addScrollTriggerElement(tr, () => {
                    effect.active = i;
                    effect.update();
                }, () => {
                    effect.active = i - 1;
                    effect.update();
                });
            });
        }
    });

    [...document.querySelectorAll('.tab')].forEach(dom => {
        if (dom.dataset.tabuuid) {
            return;
        }
        dom.dataset.tabuuid = uuidv4();
        const setup = {
            dom: dom,
            bars: dom.querySelectorAll('.tab__bar > div'),
            content: dom.querySelectorAll('.tab__content > div'),
            change: (index) => {
                [...setup.bars].forEach((bar, i) => {
                    if (index === i) {
                        bar.classList.add('active');
                    } else {
                        bar.classList.remove('active');
                    }
                });
                [...setup.content].forEach((content, i) => {
                    if (index === i) {
                        content.classList.add('view');
                    } else {
                        content.classList.remove('view');
                    }
                });
            }
        };
        [...setup.bars].forEach((bar, i) => {
           bar.addEventListener('click', () => {
               console.log('click', i);
               setup.change(i);
           });
        });
    });


    // twig loaded swiper
    const swiper = new SwiperOnPage();
    swiper.init();

    // gallery module
    /*
    * Auto change effect
    * */
    const bringGalleryToTop = (index, images) => {
        // console.log('bringGalleryToTop', index);
        images.forEach((layer, layerIndex) => {
            // console.log(images, layerIndex, index);
            layer.style.zIndex = index === layerIndex ? 2 : 1;
        });
    };

    [...document.querySelectorAll('.gallery-module')].forEach(dom => {
       const images = [...dom.querySelectorAll('.gallery-module-img-scroll-holder > div')];
       const triggers = [...dom.querySelectorAll('.gallery-module-scroll-trigger')];
       triggers.forEach(el => {
           SWScroll.addScrollTriggerElement(el, () => {
               bringGalleryToTop(parseInt(el.dataset.index) + 1, images);
           }, () => {
               bringGalleryToTop(parseInt(el.dataset.index), images);
           })
       });

        const sliderConf = {
            speed: 0,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            allowTouchMove: false,
        };

        [...dom.querySelectorAll('.gallery-module-slider')].forEach((sliderEl) => {
            const slider = new Swiper(sliderEl, sliderConf);
            slider.autoplay.stop();

            const obs = new IntersectionObserver((entries) => {
                const entry = entries[0];

                if (entry.isIntersecting && entry.intersectionRatio > .4) {
                    slider.autoplay.start();
                } else if (!entry.isIntersecting) {
                    slider.autoplay.stop();
                    slider.setProgress(0, 0);
                }
            }, { threshold: [0, .4] })
            obs.observe(sliderEl);
        });
    });

    const splitArrow = new SplitArrow();
    splitArrow.init();

    const form = new Form();

    console.log('dom done');
    window.SWScroll.scroll(true);

    document.querySelector('.loader').classList.add('done');
});

/* generate UUID */
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
