import Swiper, {  Navigation, Pagination } from 'swiper';
import 'swiper/css';

Swiper.use([Navigation, Pagination]);

export default class SwiperOnPage {
    swiperSettings = {
        'home-stage-swiper': {
            slidesPerView: 'auto',
            spaceBetween: 20,
            breakpoints: {
                768: {
                    slidesPerView: 3
                }
            }
        },
        'grid-columns-slider': {
            slidesPerView: 'auto',
            spaceBetween: 20,
            breakpoints: {}
        },
        'mobile-menu-slider': {
            slidesPerView: 'auto',
            spaceBetween: 10,
            freeMode: true
        },
    };

    constructor() {}

    init() {
        Object.keys(this.swiperSettings).forEach(className => {
            const all = document.querySelectorAll(`.${className}`);
            all.forEach(dom => {
                // replace breakpoints by dataset
                if (dom.dataset && dom.dataset.breakpoints) {
                    this.swiperSettings[className].breakpoints = JSON.parse(dom.dataset.breakpoints);
                }
                const swiper = new Swiper(dom, this.swiperSettings[className]);
            });
        });
    }
}
