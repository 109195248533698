import axios from 'axios';
export default class Form {
    target = '';
    loading = false;

    constructor() {
        this.init();
    }

    init () {
        // form fields
        [...document.querySelectorAll('[data-form]')].forEach((form) => {
            const id = form.dataset.form;
            const formParts = form.querySelectorAll('.form__part'); // to toogle from form to success message
            const inputs = {};
            [...form.querySelectorAll('input')].forEach(dom => {
                if (dom.dataset[id]) {
                    inputs[dom.dataset[id]] = dom;
                }
                dom.addEventListener('keyup', () => {
                    if (dom.value.trim() !== '' && dom.classList.contains('error')) {
                        dom.classList.remove('error');
                    }
                });
            });
            const fields = form.querySelectorAll('.input__pill');
            const checkboxes = {};
            const errorText = form.querySelector('.input__error');
            // view fields
            [...fields].forEach((dom, i) => {
                setTimeout(() => {
                    dom.classList.add('view');
                }, 1000 + (i * 200));
            });
            // formfield -> for checkbox clicks
            [...form.querySelectorAll('[data-formfield]')].forEach(dom => {
                const formField = dom.dataset.formfield
                if (inputs[formField]) {
                    dom.addEventListener('click', () => {
                        // console.log('click', dom.dataset.formfield, inputs[dom.dataset.formfield]);
                        if (inputs[formField].value > 0) {
                            inputs[formField].value = '0';
                            [...form.querySelectorAll(`[data-formfield="${formField}"]`)].forEach(chng => {
                                chng.classList.remove('checked');
                                chng.classList.remove('error');
                            });
                        } else {
                            inputs[formField].value = '1';
                            [...form.querySelectorAll(`[data-formfield="${formField}"]`)].forEach(chng => {
                                chng.classList.add('checked');
                                chng.classList.remove('error');
                            });
                        }
                    });
                }
            });
            // submit
            form.querySelector('.input__submit').addEventListener('click', (e) => {
                e.preventDefault();
                let hasError = false;
                [...form.querySelectorAll('input')].forEach(dom => {
                    dom.classList.remove('error');
                    dom.value = dom.value.trim();
                    const isEmpty = dom.getAttribute('type') === 'hidden' ? dom.value !== '1' : dom.value === '';
                    if (dom.ariaRequired && dom.ariaRequired === 'true' && isEmpty) {
                        dom.classList.add('error');
                        hasError = true;
                        // checkbox
                        if (dom.getAttribute('type') === 'hidden') {
                            [...form.querySelectorAll([`[data-formfield="${dom.dataset[id]}"]`])].forEach((related) => {
                                related.classList.add('error');
                            });
                        }
                    } else {
                        if (dom.getAttribute('type') === 'hidden') {
                            [...form.querySelectorAll([`[data-formfield="${dom.dataset[id]}"]`])].forEach((related) => {
                                related.classList.remove('error');
                            });
                        }
                    }
                });
                if (hasError) {
                    errorText.classList.add('view');
                } else {
                    errorText.classList.remove('view');
                    if (formParts.length > 1) {
                        const data = {};
                        [...form.querySelectorAll('input')].forEach(dom => {
                           data[dom.dataset[id]] = dom.value;
                        });
                        data.formName = id;
                        data.lang = document.querySelector('html').getAttribute('lang');
                        axios.post('/api/form', data).then((res) => {
                            console.log(res.data);
                            if (res.data && !res.data.error) {
                                formParts[1].classList.remove('view'); // fade out form
                                formParts[0].classList.add('view'); // fade in success
                            } else if (res.data && res.data.error) {
                                if (res.data.msg === 'email') {
                                    [...form.querySelectorAll('input')].forEach(dom => {
                                        if (dom.dataset[id] === 'contact') {
                                            dom.classList.add('error');
                                        }
                                    });
                                }
                                errorText.classList.add('view');
                            }
                        });
                    }
                }
            });
        });
    }

    submit() {

    }
}
